<template>
  <div class="home">
    <div class="desk_enter">
      <div class="font_smoothing header_wrap_home">
        <div class="header_box">
          <div class="header_box_logo">
            <div class="header_logo_left">
              <i
                class="iconfont iconjialajiao"
                style="font-size: 22px; color: #f47175"
              ></i>
              加点辣椒
            </div>
            <div class="right_header_btn_wrap">
              <div class="right_header_btn">
                <a
                  class="a_hover_class"
                  @click="
                    select_page({
                      path: '/',
                      query: {},
                    })
                  "
                  >首页</a
                >
                <a
                  class="a_hover_class"
                  @click="
                    select_page({
                      path: '/authorization',
                      query: {},
                    })
                  "
                  >开通服务</a
                >
                <a
                  class="home_login"
                  @click="
                    select_page({
                      path: '/login',
                      query: {},
                    })
                  "
                  >登录</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="font_smoothing">
        <router-view />
      </div>
    </div>
    <div class="mobile_enter">
      <div
        style="
          width: 100%;
          height: 100vh;
          background: rgb(46, 132, 173);
          padding: 85px 35px 0 35px;
        "
      >
        <div
          style="padding: 35px 20px; background: #fff; border-radius: 10px"
          class="wow bounceIn"
          data-wow-delay="0.3s"
        >
          <div
            style="
              font-size: 16px;
              color: #333;
              font-weight: bold;
              text-align: center;
            "
          >
            一站式数字化解决方案
          </div>
          <div
            style="
              margin-top: 20px;
              font-size: 14px;
              color: #807c7c;
              font-weight: 600;
            "
          >
            <p style="margin-bottom: 10px">我们的特点：</p>
            <p>1. 为您提供独立的小程序和网站；</p>
            <p>2. 内部员工，营销工具，会员管理，会员营销；</p>
            <p>3.内容展示；</p>
            <p>4. 订单系统，数据统计；</p>
            <p>5. 线下多媒体。</p>
          </div>
          <div
            style="
              margin-top: 20px;
              font-size: 14px;
              color: #807c7c;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            <div>客服微信： {{ weixin }}</div>
            <div style="flex: 1"></div>
            <div
              style="
                background: rgb(51, 51, 51);
                color: rgb(255, 255, 255);
                padding: 3px 15px;
                border-radius: 3px;
                font-size: 12px;
              "
              v-clipboard:copy="weixin"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              复制
            </div>
          </div>
          <div
            style="
              margin-top: 20px;
              font-size: 14px;
              color: #807c7c;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            <div>技术电话： {{ phone }}</div>
            <div style="flex: 1"></div>
            <div
              style="
                background: rgb(51, 51, 51);
                color: rgb(255, 255, 255);
                padding: 3px 15px;
                border-radius: 3px;
                font-size: 12px;
              "
              v-clipboard:copy="phone"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              复制
            </div>
          </div>
          <div
            style="
              display: inline-block;
              margin-top: 35px;
              background: rgb(51, 51, 51);
              color: rgb(255, 255, 255);
              padding: 5px 35px;
              margin-left: 50%;
              transform: translateX(-50%);
              border-radius: 3px;
              font-size: 14px;
            "
          >
            立即注册
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs';
export default {
  name: "Home",
  components: {

  },
  data () {
    return {
      weixin: 'pandaOba',
      phone: '15173082639'
    }
  },
  mounted () {
    // 参考 https://www.jianshu.com/p/eff637c7ffc9
    var wow = new WOW({
      live: false
    })
    wow.init()
    this.$nextTick(() => {
      this.topFunction()
    })
  },
  methods: {
    topFunction () {
      // 兼容
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },
    select_page (page) {
      this.$router.push(page)
    },
    onCopy: function (e) {
      alert('复制成功: ' + e.text)
    },
    onError: function () {
      alert('复制失败')
    }
  }
};
</script>
<style scoped>
@import '../assets/scss/icon.scss';
@import '../assets/scss/home.scss';
</style>
