<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {},
  data () {
    return {
    }
  },
  mounted () {
    var that = this
    // 本笔记本宽高：1536*684
    // 1rem = 100px
    // console.log('屏幕宽度:', w, window.innerWidth, document.documentElement.clientWidth, document.body.clientWidth)
    // console.log('屏幕高度:', window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight)
    // console.log('rem:', html.style.fontSize)
    window.onresize = function () {
      that.Adjust_font_size()
    }
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    console.log('--------------获取屏幕宽度---------------')
    // this.$store.commit('SET_width', w)
    // 页面开始加载时修改font-size
    var html = document.getElementsByTagName('html')[0]
    // 这里的750是指设计图的大小,自己根据实际情况改变
    // html.style.fontSize = w / 1920 * 100 + 'px'
    //html.style.fontSize = window.innerHeight / 1080 * 100 + 'px'
    html.style.fontSize = w / 1536 * 100 + 'px'
  },
  methods: {
    Adjust_font_size () {
      // 屏幕大小改变后，重新调整字体
      var html = document.getElementsByTagName('html')[0]
      // 这里的750是指设计图的大小,自己根据实际情况改变
      // html.style.fontSize = w / 1920 * 100 + 'px'
      //html.style.fontSize = window.innerHeight / 1080 * 100 + 'px'
      html.style.fontSize = window.innerWidth / 1536 * 100 + 'px'
      // 本笔记本宽高：1536*684
      // 1rem = 100px
      // console.log('屏幕宽度:', window.innerWidth, document.documentElement.clientWidth, document.body.clientWidth)
      // console.log('屏幕高度:', window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight)
      // console.log('rem:', html.style.fontSize)
    },
  }
}
</script>
<style>
@import './assets/scss/common.scss';
</style>
