import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

//////////////////////////////////////////////////////////
// 在VUE中路由遇到Error: Avoided redundant navigation to current location:报错显示是路由重复，
// 虽然对项目无影响，但是看到有红的还是不舒服。
// 于是查了一下发现可以这样解决
// 在你引入VueRouter的时候再加上一句话：

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
/////////////////////////////////////////////////////////

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        children: [{
                path: '',
                component: () =>
                    import( /* webpackChunkName: "about" */ "../views/Home_index.vue"),
            },
            {
                path: '/authorization',
                component: () =>
                    import( /* webpackChunkName: "about" */ "../views/applet_authorization.vue"),
            }
        ]
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Login.vue"),
    },
    {
        path: "/formal_edit",
        name: "FormalEdit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/FormalEdit.vue"),
    },
    {
        path: "/controlpanel",
        name: "ControlPanel",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/ControlPanel.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;