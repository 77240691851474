import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)


import VueClipBoard from 'vue-clipboard2'

Vue.use(VueClipBoard)


import axios from 'axios'

Vue.prototype.$ajax = axios // 重命名
axios.defaults.baseURL = 'https://zhituixing.didixiufang.com'

// import VueCropper from 'vue-cropper'
// Vue.use(VueCropper)


// 解决打印显示为object的问题
Vue.prototype.print = (title, obj, type) => {
    type = type || "log";
    let content = JSON.parse(JSON.stringify(obj));
    console[type](title, content)
}

// 单独安装的animate.css
import 'animate.css';

// 通过安装wowjs时安装的特定的animate.css
import 'wowjs/css/libs/animate.css'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')