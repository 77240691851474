import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        session: window.sessionStorage.getItem('session') || '',
        accept_data: JSON.parse(window.sessionStorage.getItem('accept_data')) || null,   //管理员账号信息
        accept_data_child_account: JSON.parse(window.sessionStorage.getItem('accept_data_child_account')) || null,  //授权账号信息，如果无，则为null
        // 以下几个全局变量如此引入的目的是为了刷新页面时仍然保持显示刷新之前的页面，但是临时数据会被清除
        controlpanel_menu_isActive_index: window.sessionStorage.getItem('controlpanel_menu_isActive_index') || 0,
        controlpanel_template_name: window.sessionStorage.getItem('controlpanel_template_name') || '',
        controlpanel_current_menu_son: window.sessionStorage.getItem('controlpanel_current_menu_son') || ''
    },
    mutations: {
        SET_TOKEN(state, data) {
            state.session = data
            window.sessionStorage.setItem('session', data)
        },
        SET_accept_data(state, data) {
            state.accept_data = data
            window.sessionStorage.setItem('accept_data', JSON.stringify(data))
        },
        SET_accept_data_child_account(state,data){
            state.accept_data_child_account = data
            window.sessionStorage.setItem('accept_data_child_account', JSON.stringify(data))
        },
        controlpanel_menu_isActive_index(state, data) {
            state.controlpanel_menu_isActive_index = 1 * data
            window.sessionStorage.setItem('controlpanel_menu_isActive_index', 1 * data)
        },
        controlpanel_template_name(state, data) {
            state.controlpanel_template_name = data
            window.sessionStorage.setItem('controlpanel_template_name', data)
        },
        controlpanel_current_menu_son(state, data) {
            state.controlpanel_current_menu_son = data
            window.sessionStorage.setItem('controlpanel_current_menu_son', data)
        }
        /*
          设置：this.$store.commit('', data)
          使用：this.$store.state.accept_data
        */
    },
    actions: {},
    modules: {}
})